import React from 'react';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Box, Stack } from '@chakra-ui/react';
import ContentContainer from '@bit/matternet.shared.components.content-container';
import PriorityEventsView from '@/src/views/priorityEvents';
import ActiveFlightsView from '@/src/views/activeFlights';
import { withApollo } from '@/src/lib/apollo';
import AppAccessCheck from '@/src/utils/AppAccessCheck';
// import CommandsHotkeys from '@/src/views/commands/CommandsHotkeys';

// Silly typescript
// look for better solution(s) https://github.com/vercel/next.js/discussions/16522
type StaticProps = {
  locale: string;
};

export const getStaticProps = async ({ locale }: StaticProps) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'flight-states'])),
  },
});

const Index = () => (
  <AppAccessCheck>
    <Head>
      <title>Priority Events · Matternet Mission Control</title>
    </Head>
    <ContentContainer>
      {/* TODO: If restoring hotkeys, keep in mind access controls! */}
      {/* <CommandsHotkeys /> */}
      <Box>
        <Stack spacing={4} shouldWrapChildren>
          <PriorityEventsView />
          <ActiveFlightsView />
        </Stack>
      </Box>
    </ContentContainer>
  </AppAccessCheck>
);

// @ts-ignore
export default withApollo({ ssr: false })(Index);
